// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-boost-js": () => import("./../../../src/pages/boost.js" /* webpackChunkName: "component---src-pages-boost-js" */),
  "component---src-pages-cloudsecops-js": () => import("./../../../src/pages/cloudsecops.js" /* webpackChunkName: "component---src-pages-cloudsecops-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contractvehicles-js": () => import("./../../../src/pages/contractvehicles.js" /* webpackChunkName: "component---src-pages-contractvehicles-js" */),
  "component---src-pages-covalent-js": () => import("./../../../src/pages/covalent.js" /* webpackChunkName: "component---src-pages-covalent-js" */),
  "component---src-pages-digitalagility-js": () => import("./../../../src/pages/digitalagility.js" /* webpackChunkName: "component---src-pages-digitalagility-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-kubernetes-js": () => import("./../../../src/pages/kubernetes.js" /* webpackChunkName: "component---src-pages-kubernetes-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-solutionpage-js": () => import("./../../../src/templates/solutionpage.js" /* webpackChunkName: "component---src-templates-solutionpage-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */)
}

